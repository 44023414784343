import axios from '@/axios'
const route = 'budget/service'
export default {

    searchStage(searchStage) {
        return axios.get(route + '/search_stage' + '?search=' + searchStage)
    },


    getServices(budget_id) {
        return axios.get(route + "/" + budget_id)
    },


    exportExcel(cost_id) {
        return axios.get(route + '/export_excel_stages/'+cost_id,{responseType: 'blob'})
    },

}
