<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Etapas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>

            </v-toolbar>

            <v-simple-table dense>
                <thead>
                    <tr>
                        <th class="text-left">
                            Etapa
                        </th>
                        <th class="text-right">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="stage in stages" :key="stage[0].id">
                        <td>{{ stage[0].code + " - " + stage[0].name }}</td>
                        <td class="text-right">{{ calcTotal(stage[0].id) }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2; color: white;">Total: {{ formatMoney(grandTotal) }}</td>

                    </tr>
                </tfoot>
            </v-simple-table>


        </v-card>

    </v-container>
</template>

<script>
import service from "./service";
import { mapGetters } from "vuex";

export default {
    data: () => ({
        costId: null,
        stages: [],
        budgetId: null,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },

        ],
    }),
    computed: {
        ...mapGetters("Cost", ["getCostId"]),
    },
    watch: {

    },
    created() {
        this.costId = this.getCostId;
        this.getServices();
    },
    methods: {
        getServices() {
            service
                .getServices(this.costId)
                .then((result) => {
                    this.stages = result.data.stages;
                    this.compositions = result.data.compositions;
                    this.grandTotal = this.calcGrandTotal(this.compositions);
                })
                .catch()
                .finally();
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.exportExcel(this.costId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'export.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal(stage_id) {
            //se não tem nenhuma composicao para essa estapa
            if (this.compositions[stage_id] != undefined) {
                let total = this.compositions[stage_id].reduce((acumulator, current) => {
                    return acumulator + current.total_with_tax * current.pivot.quantity;
                }, 0);
                return this.formatMoney(total);
            } else {
                //caso nao tenha composicao
                return this.formatMoney(0);
            }
        },

        calcGrandTotal(compositions) {
            let total = 0;
            for (let key in compositions) {
                let subtotal = compositions[key].reduce((acumulator, current) => {
                    return acumulator + current.total_with_tax * current.pivot.quantity;
                }, 0);

                total += subtotal;
            }

            return total;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

